import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Card from '@/components/common/Card'

const RecruitList = props => {
  return (
    <Root>
      <Main>
        <List>
          <List_Item
            title={(
              <Fragment>WEBエンジニア</Fragment>
            )}
            body={(
              <Fragment>WEBサイトおよびWEBアプリケーションの開発経験のあるエンジニアを募集しています。</Fragment>
            )}
            link={{
              to: '/recruit/web-engineer/',
              text: '募集要項'
            }}
          />
          <List_Item
            title={(
              <Fragment>WEBデザイナー</Fragment>
            )}
            body={(
              <Fragment>WEBおよびグラフィックデザインの経験があるデザイナーを募集しています。</Fragment>
            )}
            link={{
              to: '/recruit/web-designer/',
              text: '募集要項'
            }}
          />
          <List_Item
            title={(
              <Fragment>WEBディレクター</Fragment>
            )}
            body={(
              <Fragment>コーポレートサイトやECサイトの制作経験があるディレクターを募集しています。</Fragment>
            )}
            link={{
              to: '/recruit/web-director/',
              text: '募集要項'
            }}
          />
        </List>
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Main = styled.div``

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const List_Item = styled(Card)`
  width: 50%;
  @media ${mq.and(tabletS, mobile)} {
    width: 100%;
  }
`

export default ThemeConsumer(RecruitList)
