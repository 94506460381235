import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mq } from '@/utils/helpers'
import { desktop, laptop, tablet, mobile } from '@/utils/media'
import Typography from '@/components/common/Typography'
import GhostButton from '@/components/common/GhostButton'

const RecruitEntry = props => (
  <Root>
    <Root_Content theme={props.theme}>
      <Title variant="h1" component="h2" lang="en" theme={props.theme}>ENTRY</Title>
      <Actions>
        <Button type="contrast" to="/recruit/entry/" component={Link}>応募フォームへ</Button>
      </Actions>
    </Root_Content>
  </Root>
)

export default ThemeConsumer(RecruitEntry)

const Root = styled.div`
`

const Root_Content = styled.div`
  padding: 80px 0;
  background: ${props => props.theme.background.contrast};
  text-align: center;
`

const Title = styled(Typography)`
  text-align: center;
  color: ${props => props.theme.background.default};
`

const Actions = styled.div`
  display: flex;
  @media ${mq.and(desktop, laptop, tablet)} {
    margin-top: 32px;
    justify-content: center;
  }
  @media ${mq.and(mobile)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }
`

const Button = styled(GhostButton)`
  width: 180px;
  @media ${mq.and(desktop, laptop, tablet)} {
    margin: 0 8px;
  }
  @media ${mq.and(mobile)} {
    margin-top: 16px;
  }
`
